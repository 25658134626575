<template>
  <div class="navbar-container d-flex content align-items-center">

    <b-modal title="Expired Session" class="export-options" @ok="logout" ok-title="OK" :active.sync="promptSession">
      <p>Your session has expired. Choose OK to login again and continue. </p>
    </b-modal>

    <b-modal ref="modal" v-model="promptBatch" title="Send Batch to FTP">
      <p class="my-2">
        Send Delivery Order PDF Batch to FTP ?
      </p>
      <template #modal-footer>
        <b-button size="md" variant="success" @click="sendFTP()">
          Yes
        </b-button>
        <b-button size="md" variant="danger" @click="promptBatch = false">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.name }}
            </p>
            <small> {{role}}</small>
          </div>

          <feather-icon icon="UserIcon" />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="viewProfile"
        >
          <feather-icon
            size="12"
            icon="EyeIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="changePassword"
        >
          <feather-icon
            size="12"
            icon="LockIcon"
            class="mr-50"
          />
          <span>Password</span>
        </b-dropdown-item>
        <!--<b-dropdown-item
          v-if="!isMobile"
          link-class="d-flex align-items-center"
          @click="promptBatch=true"
        >
          <feather-icon
            size="12"
            icon="SendIcon"
            class="mr-50"
          />
          <span>Send Batch</span>
        </b-dropdown-item>
        -->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="12"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-show="permission"
          link-class="d-flex align-items-center"
          @click="linkToUserGuide"
        >
          <feather-icon
            size="12"
            icon="BookIcon"
            class="mr-50"
          />
          <span>User Guide</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import { userAccess, dateFormat, sleep } from "@/utils/utils";
// import {
//   BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
// } from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    // BLink,
    // BNavbarNav,
    // BNavItemDropdown,
    // BDropdownItem,
    // BDropdownDivider,
    // BAvatar,

    // Navbar Components
    // DarkToggler,
  },
  data () {
    return {
      promptSession: false,
      role: '',
      counter: 0,
      currentUser:'',
      promptBatch:false,
      isMobile:false
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    // user() {
    //   return this.$store.getters['auth/getActiveUser']
    // },
    permission() {
      let result = userAccess("User Guide", "userGuides_menu");
      result = result.view
      console.log("result", result);
      
      return result;
    },
    user () {
      /* eslint-disable */
      const getUser = this.$store.getters['auth/getActiveUser']
      
      let roleRef = getUser.roleRef ? (getUser.roleRef.level? getUser.roleRef.level : "" ): ""
      this.role = roleRef

      
      // var firstName = getUser.props ? getUser.props.firstName ? getUser.props.firstName : '' : '';
      // var lastName = getUser.props ? getUser.props.lastName ? getUser.props.lastName : '' : '';
      
      // this.name = (`${firstName} ${lastName}`).trim();
      // const status = localStorage.getItem('status')
      // if (!getUser) {
      //   this.counter += 1;
      //   if (this.counter > 1 && status !== 'login') {
      //     this.promptSession = true
      //   }
      // }
      // if (status === 'login') {
      //   localStorage.setItem('status', 'session')
      // }
      return getUser
  },
  },
  mounted() {
    // this.role = localStorage.getItem('currentrole')
    this.$store
      .dispatch('auth/fetchUser')
      .catch(err => console.log(err))
    // this.getUser(this.$session.get('userID'))
    //   .then(res => {
    //     console.log(res)
    //     this.currentUser = res.data
    //   })
    this.isMobile = window.matchMedia("(max-width: 761px)").matches;
  },
  methods: {
    ...mapActions({
      getUser : 'auth/getUser',
      addLog : 'log/addLog',
      sendBatchToFTP: 'delivery/sendBatchToFTP'
    }),
    sendFTP(){
      this.sendBatchToFTP().then(()=>{
        this.promptBatch=false;
        this.$bvToast.toast("Send Batch is Successful", {
            title: "Success",
            variant: "success",
            solid: true,
          });
      })
    },

    logout() {
      var username= this.user.name;
      var email = this.user.email;
      this.addLog({
        email:email,
        message:"Logged Out Manually",
        name:username,
      })
      .then((res) => {

        this.$bvToast.toast("Log Out Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        this.$session.clear()
        this.$session.destroy()
        window.location.reload()
      })
      

      // This is just for demo Purpose. If user clicks on logout -> redirect
      // this.$router.push('/pages/login').catch(() => {})
    },
    linkToUserGuide(){
      // Load the PDF file from the assets directory
      const pdfUrl = '/pdf/userGuide.pdf';
      // Open the PDF in a new tab/window
      window.open(pdfUrl, '_blank');
    },
    changePassword() {
      this.$router.push({ name: 'user-change-password' });
    },
    viewProfile() {
      this.$router.push({ name: 'user-view-profile' });
    }
  },
}
</script>
