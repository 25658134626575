export default [
  {
    navId: 'warranty-inventories',
    title: 'Inventory',
    icon: 'UsersIcon',
    role: 'Warranty',
    children: [
      {
        navId: 'warranty-inventories-all',
        title: 'All Non Drcc Item',
        route: 'warranty-inventories-all',
        icon: 'UsersIcon',
        role: 'Warranty',
      }
    ]
  },
  {
      navId: 'warranty-list',
      title: 'Warranty Register',
      icon: 'UsersIcon',
      role: 'Warranty',
      children: [
          // {
          //   navId: 'admin-warranty-internal',
          //   title: 'Internal Warranty Registration',
          //   route: 'admin-warranty-internal',
          //   icon: 'UsersIcon',
          //   role: 'Admin',
          // },
          {
              navId: 'warranty-list-paperless',
              title: 'Paperless Warranty Registration',
              route: 'warranty-list-paperless',
              icon: 'UsersIcon',
              role: 'Warranty',
          },
      ]
  }
];