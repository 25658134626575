export default [
  {
    navId: 'dashboard',
    title: 'Dashboard',
    icon: 'MonitorIcon',
    children: [
      {
        navId: 'dashboard-dealer',
        title: 'Dealer',
        route: 'dashboard-dealer',
        icon: 'MonitorIcon',
      },
      {
        navId: 'dashboard-sales-admin',
        title: 'Sales Admin',
        route: 'dashboard-sales-admin',
        icon: 'MonitorIcon',
      },
    ]
  },
  {
    navId: 'dealer-orders',
    title: 'Purchase Order',
    icon: 'ShoppingCartIcon',
    children: [
      {
        navId: 'order',
        title: 'Order',
        route: 'order',
        icon: 'ShoppingCartIcon',
      },
      {
        navId: 'po-fail',
        title: 'PO Fail',
        route: 'po-fail',
        icon: 'ShoppingCartIcon',
      },
    ]
  },
  
  {
    navId: 'dealer-sales',
    title: 'Change Sales Order',
    icon: 'InboxIcon',
    children: [
      {
        navId: 'view-sales-order',
        title: 'View Sales Order',
        route: 'view-sales-order',
        icon: 'InboxIcon',
      },
    ]
  },
]
