export default [
  {
    navId: "admin-item-master",
    title: "Master Data",
    icon: "ToolIcon",
    children: [
      {
        navId: "admin-item",
        title: "Item Master",
        route: "item",
        icon: "ToolIcon",
      },
      {
        navId: "admin-item-bundles",
        title: "Bundles Master",
        route: "bundles",
        icon: "ToolIcon",
      },
      {
        navId: "admin-dealer",
        title: "Dealer Master",
        route: "dealer",
        icon: "ToolIcon",
      },
      {
        navId: "admin-postal",
        title: "POS Code Master",
        route: "post-code",
        icon: "ToolIcon",
      },
      {
        navId: "admin-industry-type",
        title: "Industry Type",
        route: "industry-type",
        icon: "ToolIcon",
      },
      {
        navId: "admin-product-hierarchy",
        title: "PH Mapping Master",
        route: "master-ph",
        icon: "ToolIcon",
      },
    ],
  },

  {
    navId: "warranty-menus",
    title: "Warranty",
    icon: "FileTextIcon",
    children: [
      {
        navId: "admin-equipment-warranty",
        title: "Applied Equipment",
        route: "equipment-warranty",
        icon: "FileTextIcon",
      },
      {
        navId: "admin-project-warranty",
        title: "Project Warranty",
        route: "project-warranty",
        icon: "FileTextIcon",
      },
      {
        navId: "admin-warranty-external",
        title: "Paperless Warranty",
        route: "paperless-warranty",
        icon: "FileTextIcon",
      },
      {
        navId: "warranty-status",
        title: "Warranty Status",
        route: "warranty-status",
        icon: "FileTextIcon",
      },
      {
        navId: "admin-all-warranty",
        title: "All Reg. Warranty",
        route: "all-warranty",
        icon: "FileTextIcon",
      },
      {
        navId: "admin-pending-review-warranty",
        title: "Pending Review",
        route: "pending-review",
        icon: "FileTextIcon",
      },
    ],
  },
  {
    navId: "admin-warranty-master",
    title: "Warranty Master",
    icon: "AlignJustifyIcon",

    children: [
      {
        navId: "admin-base-warranty",
        title: "Base Warranty",
        route: "base-warranty",
        icon: "AlignJustifyIcon",
      },
      {
        navId: "admin-option-warranty",
        title: "Option Warranty",
        route: "option-warranty",
        icon: "AlignJustifyIcon",
      },
      {
        navId: "admin-compressor-warranty",
        title: "Compressor Warranty",
        route: "compressor-warranty",
        icon: "AlignJustifyIcon",
      },

      {
        navId: "admin-pcode",
        title: "PCode",
        route: "pcode",
        icon: "AlignJustifyIcon",
      },
      {
        navId: "admin-campaign",
        title: "Campaign",
        route: "campaign",
        icon: "AlignJustifyIcon",
      },
      {
        navId: "admin-promo-code",
        title: "Promo Code Batch",
        route: "promo-code",
        icon: "AlignJustifyIcon",
      },
      {
        navId: "system-promo",
        title: "System Promo",
        icon: "AlignJustifyIcon",
        route: "system-promo",
      },
      {
        navId: "term-condition",
        title: "Terms and Condition",
        icon: "AlignJustifyIcon",
        route: "terms-condition",
      },
    ],
  },
  {
    navId: "admin-inventory",
    title: "Inventory",
    icon: "CheckCircleIcon",

    children: [
      {
        navId: "admin-inventory-all",
        title: "View Inventory",
        route: "view-inventory",
        icon: "CheckCircleIcon",
      },
      {
        navId: "dealer-inventory",
        title: "Inventory Dealer",
        route: "inventory-dealer",
        icon: "CheckCircleIcon",
      },
    ],
  },
  {
    navId: "delivery-orders",
    title: "Delivery Orders",
    icon: "HomeIcon",
    children: [
      {
        navId: "delivery-orders-daikin",
        title: "Daikin Warehouse",
        route: "delivery-orders-daikin",
        icon: "HomeIcon",
      },
      {
        navId: "delivery-orders-sumitomo",
        title: "Sumitomo Warehouse",
        route: "delivery-orders-sumitomo",
        icon: "HomeIcon",
      },
    ],
  },
  {
    navId: "admin-shipping",
    title: "Shipping",
    icon: "TruckIcon",
    children: [
      {
        navId: "shipping-daikin",
        title: "Daikin Warehouse",
        route: "shipping-daikin",
        icon: "TruckIcon",
      },
      {
        navId: "shipping-sumitomo",
        title: "Sumitomo Warehouse",
        route: "shipping-sumitomo",
        icon: "TruckIcon",
      },
    ],
  },
  {
    navId: "self_collect",
    title: "Self Collect",
    icon: "PackageIcon",
    children: [
      {
        navId: "self-collect-daikin",
        title: "Daikin Warehouse",
        route: "self-collect-daikin",
        icon: "PackageIcon",
      },
      {
        navId: "self-collect-sumitomo",
        title: "Sumitomo Warehouse",
        route: "self-collect-sumitomo",
        icon: "PackageIcon",
      },
    ],
  },
  {
    navId: "admin-return-delivery",
    title: "Return Delivery",
    icon: "ArrowLeftCircleIcon",
    children: [
      {
        navId: "return-delivery-daikin",
        title: "Daikin Warehouse",
        route: "return-delivery-daikin",
        icon: "ArrowLeftCircleIcon",
      },
      {
        navId: "return-delivery-sumitomo",
        title: "Sumitomo Warehouse",
        route: "return-delivery-sumitomo",
        icon: "ArrowLeftCircleIcon",
      },
    ],
  },
  {
    navId: "admin-return-delivery",
    title: "Manual Return",
    icon: "RewindIcon",
    children: [
      {
        navId: "return-delivery-daikin",
        title: "Daikin Warehouse",
        route: "manual-return-delivery-daikin",
        icon: "ArrowLeftCircleIcon",
      },
      {
        navId: "return-delivery-sumitomo",
        title: "Sumitomo Warehouse",
        route: "manual-return-delivery-sumitomo",
        icon: "ArrowLeftCircleIcon",
      },
    ],
  },
  {
    navId: "admin-redelivery",
    title: "Redelivery",
    icon: "RefreshCcwIcon",
    children: [
      {
        navId: "redelivery-daikin",
        title: "Daikin Warehouse",
        route: "redelivery-daikin",
        icon: "RefreshCcwIcon",
      },
      {
        navId: "redelivery-sumitomo",
        title: "Sumitomo Warehouse",
        route: "redelivery-sumitomo",
        icon: "RefreshCcwIcon",
      },
    ],
  },
  {
    navId: "admin-retrigger-api",
    title: "Retrigger API",
    icon: "RotateCwIcon",
    children: [
      {
        navId: "resend-to-sap",
        title: "Resend to SAP",
        route: "resend-to-sap",
        icon: "RotateCwIcon",
      },
    ],
  },
  {
    navId: "admin-manual-create",
    title: "Manual Create",
    icon: "PlusIcon",
    children: [
      {
        navId: "manual-create-delivery",
        title: "Delivery Order",
        route: "manual-create-delivery",
        icon: "PlusIcon",
      },
      {
        navId: "manual-create-inventory",
        title: "Inventory",
        route: "manual-create-inventory",
        icon: "PlusIcon",
      },
      {
        navId: "manual-create-by-upload",
        title: "By Upload",
        route: "manual-create-by-upload",
        icon: "PlusIcon",
      },
    ],
  },
  {
    navId: "admin-utility-tools",
    title: "Utility Tools",
    icon: "EditIcon",
    children: [
      {
        navId: "mass-do-update",
        title: "Mass DO Update",
        route: "mass-do-update",
        icon: "EditIcon",
      },
      {
        navId: "transaction-log-record",
        title: "Transaction Log Record",
        route: "transaction-log-record",
        icon: "EditIcon",
      },
      {
        navId: "edit-do-status",
        title: "Edit DO Status",
        route: "edit-do-status",
        icon: "EditIcon",
      },
      {
        navId: "clear-amk-spare-part",
        title: "Clear Amk Spare Part",
        route: "clear-amk-spare-part",
        icon: "EditIcon",
      },
      {
        navId: "mass-update-email",
        title: "Mass Update email",
        route: "mass-update-email",
        icon: "EditIcon",
      },{
        navId: "return-order-type-exception",
        title: "Return Order Type Exception",
        route: "return-order-type-exception",
        icon: "EditIcon",
      },
      {
        navId: "replace-do",
        title: "DO Replacement",
        route: "replace-do",
        icon: "EditIcon",
      },
    ],
  },
  {
    navId: "admin-manage-users",
    title: "Settings",
    icon: "SettingsIcon",

    children: [
      {
        navId: "role",
        title: "Role",
        route: "role",
        icon: "UserCheckIcon",
      },
      {
        navId: "admin-users-admin",
        title: "User Management",
        route: "user-management",
        icon: "UsersIcon",
      },
      {
        navId: "admin-holidays",
        title: "Holiday Management",
        route: "holiday-management",
        icon: "SunIcon",
      },
      {
        navId: "admin-dashboard-password",
        title: "Dashboard Password",
        route: "dashboard-password",
        icon: "MonitorIcon",
      },
      {
        navId: "admin-gst",
        title: "GST",
        route: "gst",
        icon: "UsersIcon",
      },
    ],
  },

  {
    navId: "report-menus",
    title: "Report",
    icon: "ListIcon",

    children: [
      {
        navId: "report-inventory",
        title: "Inventory Report",
        route: "inventory-report",
        icon: "ListIcon",
      },
      {
        navId: "report-rsp-order",
        title: "RSP Order Report",
        route: "rsp-order-report",
        icon: "ListIcon",
      },
      {
        navId: "report-pcode",
        title: "PCode History Report",
        route: "pcode-report",
        icon: "ListIcon",
      },
      {
        navId: "report-promo-code",
        title: "Promo Code Report",
        route: "promo-code-report",
        icon: "ListIcon",
      },
      {
        navId: "report-do",
        title: "DO Report",
        route: "do-report",
        icon: "ListIcon",
      },{
        navId: "report-expiring-warranty",
        title: "Expiring Warranty Report",
        route: "expiring-warranty-report",
        icon: "ListIcon",
      },
      {
        navId: "report-expired-warranty",
        title: "Expired Warranty Report",
        route: "expired-warranty-report",
        icon: "ListIcon",
      },
    ],
  },
];
