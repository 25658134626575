export default [
  {
    navId: 'customer-warranty-paperless',
    title: 'Warranty-paperless',
    icon: 'UsersIcon',
    role: 'Customer',
    children: [
      {
        navId: 'warranty-registration-all',
        title: 'All Registered Warranty',
        route: 'warranty-list-paperless-individual',
        icon: 'UsersIcon',
        role: 'Customer',
      }
    ]
  },
  // {
  //     navId: 'warranty-list',
  //     title: 'Warranty Register',
  //     icon: 'UsersIcon',
  //     role: 'Warranty',
  //     children: [
  //         // {
  //         //   navId: 'admin-warranty-internal',
  //         //   title: 'Internal Warranty Registration',
  //         //   route: 'admin-warranty-internal',
  //         //   icon: 'UsersIcon',
  //         //   role: 'Admin',
  //         // },
  //         {
  //             navId: 'warranty-list-paperless',
  //             title: 'Paperless Warranty Registration',
  //             route: 'warranty-list-paperless',
  //             icon: 'UsersIcon',
  //             role: 'Warranty',
  //         },
  //     ]
  // }
];